.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 20px;
}

/* .blog-wrap-recommended img {
    width: 100%;
  } */

.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-desc {
  padding: 1rem;
  margin-top: 1.25rem;
  font-family: "Libre Franklin", Helvetica, sans-serif;
  background: #fff;
  font-size: 1.125rem;
  line-height: 1.25rem;
  -webkit-text-size-adjust: 100%;
  color: rgba(0, 0, 0, 0.95);
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}
.container {
  /* max-width: 1140px; */
  width: 100%;
  /* margin: 0 auto; */
  padding: 1rem 0;
}
.blog-img {
  height: 400px;
  width: 900px;
}

.blog-title {
  font-family: "Noto Serif", Georgia, serif;
  font-weight: 700;
  text-align: left;
}

.blog-box {
  margin-top: 20px;
  margin-right: 0;
  box-shadow: 0 0 3px 1px #bbb;
  border-top: 9px solid black;
  background-color: white;
}
.blog-box strong {
  padding: 1rem;
  margin-top: 0.5rem;
  font-family: "Libre Franklin", Helvetica, sans-serif;
  background: #fff;
  font-size: 1.5rem;
  line-height: 1.75rem;
  -webkit-text-size-adjust: 100%;
  color: rgba(0, 0, 0, 0.95);
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}
.blog-box p {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  text-align: justify;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background: #fff;
  font-size: 15px;
  line-height: 1.25rem;
  -webkit-text-size-adjust: 100%;
  color: rgba(0, 0, 0, 0.95);
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}

.blog-desc p {
  margin: 6px;
}

.recommend-title {
  font-size: 20px;
  font-family: "Libre Franklin", Helvetica, sans-serif;

  line-height: 1.75rem;

  text-align: left;
}
.blog-recommend {
  margin-top: 10px;
}

.blog-link {
  text-decoration: none;
}

@media (max-width: 720px) {
  .blog-img {
    height: 100%;
    width: 100%;
  }
}
